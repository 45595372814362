import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';
import RectangleLink from '../../components/RectangleLink';
import enMessages from '../../i18n/legal/depositInsurance/en.js';
import ruMessages from '../../i18n/legal/depositInsurance/ru.js';
import enMainMessages from '../../i18n/LegalInformation/en.js';
import ruMainMessages from '../../i18n/LegalInformation/ru.js';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const messagesMain = {
  en: enMainMessages,
  ru: ruMainMessages,
};

const DepositInsurancePage = ({ pathContext: { locale } }) => {
  const section = 'depositInsurance';

  const isBrowser = typeof window !== "undefined";

  if (isBrowser && locale === 'en') {
    window.location.replace('/en/legal/requisite');
  }

  return (
    <TemplateLegalPage
      locale={locale}
      messages={messages[locale]}
      messagesMain={messagesMain[locale]}
      section={section}
    >
      <div className="LegalInformation-Text">
        {messages[locale].content.map((item) => {
          if (item.text) {
            return (
              <div
                dangerouslySetInnerHTML={{ __html: item.text }}
                key={item.text}
              />
            );
          }

          if (item.items) {
            return (
              <React.Fragment key={item.title}>
                {item.title ? (
                  <h4 style={{ margin: '2em 0 0' }}>{item.title}</h4>
                ) : null}

                {item.items.map(((subitem) => {
                  return (
                    <RectangleLink
                      key={subitem.link}
                      label={subitem.label}
                      link={subitem.link}
                      title={subitem.title}
                      target="_blank"
                    />
                  );
                }))}
              </React.Fragment>
            );
          }

          return null;
        })}
      </div>
    </TemplateLegalPage>
  );
};

export default DepositInsurancePage;
